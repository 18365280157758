/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.MyAccountInvoice {
    &-CreationDate {
        font-size: 16px;
        margin-block-end: 18px;
    }

    &-OrderStatus {
        display: block;
        border-radius: 3px;
        border: var(--my-account-order-subheading-border);
        color: var(--my-account-order-subheading-color);
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        margin: 3px 0 0;
        padding: 5px 10px;
        text-transform: uppercase;
        inset-block-start: -1px;
        width: min-content;
    }

    &-OrderId {
        margin: 10px 0;
        display: flex;
        flex-direction: column;
    }

    &-Buttons {
        display: flex;
        margin-block-end: 25px;
        justify-content: space-between;

        @include mobile {
            margin-block-end: 40px;
        }
    }

    &-Reorder {
        margin-inline-end: 20px;

        &.Button {
            &:active {
                color: var(--link-hover);
            }

            @include hoverable {
                &:hover {
                    color: var(--link-hover);
                }
            }
        }
    }

    &-Reorder,
    &-SubscribeToStatus {
        font-weight: normal;
        width: max-content;

        @include hoverable {
            &:hover {
                text-decoration: none;
                color: var(--link-hover);
            }
        }

        &:active {
            text-decoration: none;
            color: var(--link-hover);
        }

        &:focus {
            color: var(--primary-base-color);
            text-decoration: none;
        }
    }

    &-PrintOrder {
        font-weight: normal;

        @include desktop {
            display: block;
            margin-inline-start: auto;
        }
    }

    &-Address,
    &-Products {
        margin-block-end: 14px;

        @include desktop {
            margin-block-start: 24px;
        }

        @include mobile {
            margin-block-end: 28px;
            /* stylelint-disable-next-line declaration-no-important */
            overflow-x: unset !important;
        }
    }

    &-Products {
        width: 100%;
        margin-block-start: 12px;
        table-layout: initial;

        @include mobile {
            margin-block-start: 14px;
        }

        td,
        th {
            vertical-align: middle;
        }
    }

    &-Thumbnail {
        width: 60px;
        height: 60px;
        padding: 0;
    }

    &-Row {
        height: 80px;
        padding: 10px 0;

        &:nth-child(even) {
            img {
                mix-blend-mode: multiply;
            }
        }
    }

    &-Content {
        min-height: 200px;

        @include desktop {
            display: grid;
            grid-gap: 24px;
        }
    }

    h4 {
        font-size: 14px;

        @include mobile {
            font-size: 17px;
        }
    }

    dt {
        font-weight: bold;
    }

    dl {
        display: grid;
        margin-block-start: 12px;
        grid-template-columns: 1fr 1fr;
        grid-gap: 6px;

        @include mobile {
            margin-block-start: 14px;
            grid-gap: 7px;
            margin-block-end: 28px;
        }
    }

    td,
    th {
        min-width: 0;
    }

    &-Address {
        .KeyValueTable-Wrapper {
            overflow-x: unset;
        }
    }

    &-Comments {
        margin-block-end: 40px;

        @include mobile {
            margin-block-end: 25px;
        }

        &Title {
            border-block-end: var(--my-account-content-border);
            font-size: 22px;
            margin-block-end: 25px;
            padding-block-end: 10px;
        }
    }

    &-Comment {
        display: flex;

        @include mobile {
            flex-direction: column;
        }

        dt {
            font-weight: bold;
            margin-block-end: 10px;
            margin-inline-end: 30px;
            min-width: 90px;
            width: 90px;

            @include mobile {
                margin-block-end: 5px;
                width: auto;
            }
        }

        dd {
            word-break: break-word;

            &:not(&:last-of-type) {
                @include mobile {
                    margin-block-end: 15px;
                }
            }
        }
    }

    &-Loader {
        @include mobile {
            inset-block-start: 35vh;
        }
    }




    &Information-Wrapper {
        @include desktop {
            margin-bottom: 210px;
        }
    }

    &-Actions {
        @include desktop {
            margin-top: -10px;
        }

        .MyAccountInvoiceItemsTable-AdditionalInfo {
            width: 100%;

            thead th,
            tbody td {
                min-width: unset;

                &:nth-of-type(1) {
                    width: 80px;

                    @include mobile {
                        width: 100px
                    }
                }

                &:nth-of-type(2),
                &:nth-of-type(3) {
                    width: 122px;
                }

                &:nth-of-type(4) {
                    width: 100px;
                }

                &:nth-of-type(5) {
                    width: 80px;
                }

                &:nth-of-type(6) {
                    width: 80px;
                }

                &:nth-of-type(7) {
                    width: 130px;
                }

                &:nth-of-type(8) {
                    width: 120px;
                }

                &:nth-of-type(9) {
                    width: 110px;
                }

                &:nth-of-type(10) {
                    width: 95px;
                }

                &:nth-of-type(11) {
                    width: 95px;
                }

                &:nth-of-type(10),
                &:nth-of-type(11) {
                    text-align: right;
                }

                @include mobile {
                    &:nth-of-type(1) {
                        @include mobile {
                            max-width: 121px;
                        }
                    }

                    &:nth-of-type(2) {
                        @include mobile {
                            max-width: 111px;
                        }
                    }

                    &:nth-of-type(3) {
                        @include mobile {
                            max-width: 115px;
                        }
                    }
                }
            }

            thead th {
                font-size: 14px;
                vertical-align: bottom;
            }

            tbody td {
                @include mobile {
                    vertical-align: middle;
                }
            }
        }

        .MyAccountInvoice-Buttons {
            align-items: center;
            justify-content: flex-end;
            grid-gap: 20px;

            @include mobile {
                align-items: flex-start;
            }

            @include desktop {
                position: absolute;
                top: -68px;
                right: 0;
            }

            a,
            button {
                height: 36px;
                min-height: 36px;
                padding: 9px 20px;
                font-size: 14px;
            }

            // a {
            //     font-weight: 700;
            //     font-size: 14px;
            //     line-height: 18px;
            //     text-transform: uppercase;
            //     color: $secondary-color-3;
            //     display: inline-flex;
            //     align-items: center;
            //     justify-content: center;

            //     svg {
            //         margin-right: 10px;
            //     }
            // }
        }

        table {
            tbody td {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: $font-color-1;
            }
        }
    }

    &-ProductsTableHeading {
        font-weight: 600;
        font-size: 28px;
        line-height: 34px;
        color: $font-color-1;
        margin: 30px 0 20px;
    }

    &-ProductsTable {

        thead th,
        tbody td {
            min-width: unset;
            padding: 16px 10px;

            @include mobile {
                max-width: 100%;
                padding: 4px 0;
                margin: 0 10px;
                min-width: unset;
            }

            &:nth-of-type(1) {
                min-width: 270px;
                max-width: 270px;

                @include mobile {
                    border-top: 2px solid $white;
                    max-width: unset;
                }
            }

            &:nth-of-type(2) {
                min-width: 55px;

                @include mobile {
                    max-width: unset;
                }
            }

            &:nth-of-type(3) {
                max-width: 235px;

                @include mobile {
                    max-width: unset;
                }
            }

            &:nth-of-type(4),
            &:nth-of-type(5),
            &:nth-of-type(6),
            &:nth-of-type(7),
            &:nth-of-type(8) {
                text-align: right;
                min-width: 110px;
            }

            &:last-of-type {
                @include mobile {
                    border-bottom: 0;
                }
            }
        }

        thead th {
            background-color: transparent;
            border-right: 2px solid transparent;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            vertical-align: bottom;

            &:last-of-type {
                border-right: 0;
            }
        }

        tbody {
            tr {
                &:nth-of-type(even) {
                    border-radius: 4px;
                }

                &:nth-of-type(odd) {
                    background: $neutral-color-6;
                    border-radius: 4px;
                }

                @include mobile {
                    display: flex;
                    flex-direction: column;
                }
            }

            td {
                border-right: 2px solid $white;
                vertical-align: middle;

                @include mobile {
                    border-right: 0;
                    border-bottom: 2px solid $white;

                }

                &:last-of-type {
                    border-right: 0;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    color: $font-color-1;
                }

                >div {
                    @include mobile {
                        width: 100%;
                        min-width: unset;
                        max-width: unset;
                        display: flex;
                        justify-content: space-between;
                    }

                    >*:not(.MyAccountInvoice-ProductsTableDiscountWrapper) {
                        @include mobile {
                            width: 50%;
                            display: inline-flex;
                            align-items: flex-end;
                            text-align: left;
                            min-width: 50%;

                            &:nth-of-type(2) {
                                text-align: right;
                                justify-content: flex-end;
                                margin-left: auto;
                            }
                        }
                    }
                }

            }
        }

        .ExpandableContent:last-of-type {
            @include mobile {
                border: 0;
            }
        }

        .ExpandableContent-Button {
            @include mobile {
                padding: 10px;
            }

            .ChevronIcon {
                @include mobile {
                    min-width: var(--toggle-button-size);
                    min-height: var(--toggle-button-size);
                    margin-left: 18px;
                }
            }
        }

        .ExpandableContent-Content {
            @include mobile {
                display: flex;
                flex-direction: column;
            }

            td span {
                font-size: 14px;
                line-height: 20px;

                &:nth-of-type(1) {
                    font-weight: 400;
                    color: $neutral-color-3;
                    padding-right: 12px;

                    span {
                        @include mobile {
                            display: inline-block;
                        }
                    }
                }

                &:nth-of-type(2) {
                    font-weight: 600;
                    color: $font-color-1;
                }
            }
        }
    }

    &-ProductsTableSku {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $neutral-color-3;
        margin-top: 2px;
    }

    &-ProductsTableDiscountWrapper {
        @include mobile {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: flex-end;
        }
    }

    &-ProductsTableWrapper {
        overflow-x: auto;
    }

    &-ProductsTableOldPrice {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-decoration-line: line-through;
        color: $neutral-color-3;

        @include mobile {
            margin-right: 0;
        }
    }

    &-ProductsTableDiscount {
        background: $secondary-color-3;
        border-radius: 4px;
        padding: 2px 4px;
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
        color: $white;
        margin: 4px 0;
        display: inline-block;
    }

    &-ProductsTablePromoPrice {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: $secondary-color-3;
    }

    &-Totals {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;

        @include mobile {
            margin-top: 40px;
        }

        >div {
            max-width: 440px;
            width: 100%;

            @include mobile {
                max-width: 100%;
            }
        }

        div>div:not(.MyAccountInvoice-TotalsHeading) {
            color: $neutral-color-3;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 10px 0;

            span {
                color: $neutral-color-3;
            }
        }


        &Heading {
            background: $neutral-color-6;
            border-radius: 2px;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: $font-color-1;
            padding: 4px;
        }
    }

    &-Reorder {
        font-weight: 700;
        min-height: 36px;
        height: 36px;
        padding-inline: 20px;
        font-size: 14px;
        line-height: 18px;
        margin-right: 0;
    }

    &-BottomAdditionalInfo {
        margin-block: 30px;
        display: flex;
        align-items: flex-end;
        flex-direction: column;

        span {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: $font-color-1;
        }

        >div {
            margin-bottom: 8px;

            span:nth-child(1) {
                color: $font-color-2;

                @include desktop {
                    margin-right: 4px;
                }
            }

            span:nth-child(2) {
                font-weight: 600;
            }
        }

        &_isMobile {
            display: block;

            >div:not(.MyAccountInvoice-OverduePayment) {
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: 12px;
            }
        }
    }

    &-OverduePayment {
        background-color: $extra-color-15;
        padding: 6px 10px;
        border-radius: 4px;

        span {
            color: $extra-color-16 !important;
        }
    }
}